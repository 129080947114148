@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');

body {
  font-family: Rethink Sans;
  background-color: #050520;
  color: white;
}

.colored-btn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  gap: 4px;
  height: 44px;
  background: radial-gradient(100% 100% at 30% 30%, #49108B 14.57%, #7E30E1 57.97%, #E26EE5 79.35%, #F3F8FF 100%);
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #F3F8FF;
  box-shadow: 0px -3px 0px 0px rgba(73, 16, 139, 0.25) inset;

  &:hover {
    opacity: 0.9;
  }
}

.header {
  border-bottom: 1px solid #F3F8FF14;
}

.footer {
  border-top: 1px solid #F3F8FF14;
  padding: 4vh calc(calc(100% - 1000px) / 2) 4vh calc(calc(100% - 1200px) / 2);
  display: flex;
  justify-content: space-between;

  .list-group {
    margin-left: 100px;
  }

  .footer-list-header {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: rgba(229, 240, 255, 1);
    margin-bottom: 10px;
  }

  .footer-list-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: rgba(229, 240, 255, 0.7);
    cursor: pointer;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.9;
    }
  }
}

.copyright-section {
  border-top: 1px solid #F3F8FF14;
  padding: 2vh calc(calc(100% - 1000px) / 2) 2vh calc(calc(100% - 1200px) / 2);
  display: flex;
  justify-content: space-between;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: rgba(229, 240, 255, 0.4);

    &.clickable {
      cursor: pointer;
      margin-left: 50px;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.lights {
  position: absolute;
  width: auto;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.banner-badge {
  width: max-content;
  margin: 5vh auto 3vh auto;
  height: 32px;
  padding: 4px 14px;
  border-radius: 24px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, rgba(243, 248, 255, 0.16) 0%, rgba(243, 248, 255, 0.04) 100%);
  box-shadow: 0px -8px 24px 0px #E2E8FF29 inset;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: -webkit-gradient(110.53% 116.97% at -10.18% -25.85%, #7E30E1 0%, #E26EE5 68.83%, #F3F8FF 100%);
  background: -webkit-radial-gradient(110.53% 116.97% at -10.18% -25.85%, #7E30E1 0%, #E26EE5 68.83%, #F3F8FF 100%);
  background: radial-gradient(110.53% 116.97% at -10.18% -25.85%, #7E30E1 0%, #E26EE5 68.83%, #F3F8FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-white {
  font-size: 72px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: center;
  background: -webkit-gradient(100% 100% at 50% 0%, #E5F0FF 0%, rgba(229, 240, 255, 0.72) 100%);
  background: -webkit-radial-gradient(100% 100% at 50% 0%, #E5F0FF 0%, rgba(229, 240, 255, 0.72) 100%);
  background: radial-gradient(100% 100% at 50% 0%, #E5F0FF 0%, rgba(229, 240, 255, 0.72) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-colored {
  font-size: 72px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: center;
  background: -webkit-gradient(100% 121.66% at 0% 0%, #49108B 0%, #7E30E1 26.62%, #E26EE5 74.22%, #F3F8FF 100%);
  background: -webkit-radial-gradient(100% 121.66% at 0% 0%, #49108B 0%, #7E30E1 26.62%, #E26EE5 74.22%, #F3F8FF 100%);
  background: radial-gradient(100% 121.66% at 0% 0%, #49108B 0%, #7E30E1 26.62%, #E26EE5 74.22%, #F3F8FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-banner {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(229, 240, 255, 0.75);
  margin: 3vh 0;
}

.galaxy {
  width: 100%;
}

.app-screen {
  margin-left: auto;
  margin-right: auto;
}

.faq-header {
  font-family: Rethink Sans;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  background: -webkit-gradient(100% 100% at 50% 0%, #E5F0FF 0%, rgba(229, 240, 255, 0.72) 100%);
  background: -webkit-radial-gradient(100% 100% at 50% 0%, #E5F0FF 0%, rgba(229, 240, 255, 0.72) 100%);
  background: radial-gradient(100% 100% at 50% 0%, #E5F0FF 0%, rgba(229, 240, 255, 0.72) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accordion {
  max-width: 800px;
  margin: 2rem auto;

  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: rgba(229, 240, 255, 0.04);
    cursor: pointer;
    height: 75px;
    margin-bottom: 10px;
    padding: 1rem;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    color: rgba(229, 240, 255, 0.75);

    &:hover {
      opacity: 0.9;
    }
  }

  .accordion-content {
    padding: 1rem;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    color: rgba(229, 240, 255, 0.75);
  }
}